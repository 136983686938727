import React, { useEffect } from 'react';
import minspectPurcoInspectionExample from '../../assets/minspect/examples/inspection-purco.png';
import PurCoForm from './PurCoForm';
import backgroundImage from '../../assets/backgrounds/background-1.jpg';
import { ReactComponent as PurCoLogo } from '../../assets/PurCo.svg';
import moblyxLogo from '../../assets/logos/reversed_moblyx_no_strapline_blue_circle.png';
import purcoLogo from '../../assets/logos/purco/light-logo.svg';


function MoblyxLogo() {
  return (
    <a className="moblyx-logo" href="/">
      <h1>
        <img src={moblyxLogo} alt="Moblyx Logo" />
      </h1>
    </a>
  );
}

/**
 * The application header.
 */
function Header() {
  return (
    <header className="header">
      <div className="moblyx-centre">
        <ul className="nav">
          <li className="nav-section logo">
            <MoblyxLogo />
          </li>
          <li className="nav-section mobile">TODO</li>
        </ul>
      </div>
    </header>
  );
}

function LandingTitleSection() {
  return (
    <header className="landing-title">
      <div className="moblyxs" style={{ backgroundImage: `data:image/png;base64,${backgroundImage}` }} />
      <section className="intro">
        <div className="intro-container moblyx-centre">
          <h1 className="intro-title">Getting ready for the next-generation of mobility</h1>
          <p className="intro-nonsense">Moblyx designs apps for all segments of the mobility market. We provide management solutions that can be used independently or together with other programs in your mobility technical landscape.</p>
        </div>
      </section>
    </header>
  );
}

function ClaimsBlock({ children }) {
  return (
    <div className="claims-block">
      { children }
    </div>
  );
}

function PurcoBrandingSection() {
  return (
    <section className="purco-branding claims-section">
      <div className="moblyx-centre">
        <ul className="claims-list">
          <li>
            <ClaimsBlock>
              <a href="https://purco.com">
                <PurCoLogo width="100%" />
              </a>
              <div>
                <h2>The damage recovery specialists</h2>
                <p><a href="https://www.purco.com/user/purco_who">PurCo</a>, one of the largest independent risk management companies devoted exclusively to loss prevention and professional claims resolution for the vehicle rental sector, bring you a damage inspection app <em>PurInspect</em> powered by <em>Moblyx</em></p>
              </div>
            </ClaimsBlock>
          </li>
        </ul>
      </div>
    </section>
  );
}

function MInspectSection() {
  return (
    <section className="minspect-section" id="purinspect" style={{ marginTop: '0' }}>
      <div className="moblyx-centre">
        <header className="minspect-header">
          <div className="purinspect-logo">
            <img src={purcoLogo} alt="PurInspect Vehicle Inspections" />
          </div>
          <h2 className="minspect-title">The new way to Inspect your vehicles</h2>
          <p className="minspect-blurb">Simplify the way your company manages your vehicle inspections. PurInspect enables you to record consistent, shareable and searchable inspections for your company.</p>
        </header>
        <div className="minspect-summary">
          <div className="container">
            <div className="minspect-summary-points">
              <ul className="points">
                <li>
                  <h4 className="point-title">Record on your phone</h4>
                  <span className="point-description">Share the app with your employees and inspect wherever you are.</span>
                </li>
                <li>
                  <h4 className="point-title">Interactive vehicle diagrams</h4>
                  <span className="point-description">Accurately record damages on the variety of vehicle diagram models.</span>
                </li>
                <li>
                  <h4 className="point-title">Configurable to your business needs</h4>
                  <span className="point-description">
                    Tailor your inspection to suit your business needs. This includes support for many inspection types:
                    <span style={{ fontStyle: 'italic' }}> Rental check-in & check-out, Regulatory, Compliance, Vehicle Handover, Daily Walkaround and more.</span>
                  </span>
                </li>
                <li>
                  <h4 className="point-title">Store inspections in the cloud</h4>
                  <span className="point-description">Digitize your inspections and back them up to the cloud so you can access wherever you are in the world.</span>
                </li>
                <li>
                  <h4 className="point-title">Share with your customers</h4>
                  <span className="point-description">Send personalized emails to your customer immediately after completing their inspection.</span>
                </li>
              </ul>
            </div>
            <div className="minspect-summary-example">
              <div className="summary-example-image-con">
                <img src={minspectPurcoInspectionExample} alt="An example inspection record." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function RegistrationSection() {
  return (
    <section className="purco-section">
      <div className="purco-interest-wrapper">
        <div className="purcos-mask">
          <div className="purcos" />
        </div>
        <div className="purco-interest-section">
          <div className="moblyx-centre purco-interest-heading">
            <h2 className="purco-interest-title">Register your Interest</h2>
            <p className="purco-interest-subtitle">We would love to answer any questions. Complete this form and we will be in touch with you shortly.</p>
          </div>
          <div className="moblyx-centre purco-interest">
            <PurCoForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default function PurCo() {
  useEffect(() => {
    document.title = 'PurCo - The damage recovery specialists';
    document.head.querySelector('meta[name="description"]').content = 'PurCo, one of the largest independent risk management companies devoted exclusively to loss prevention and professional claims resolution for the vehicle rental sector, bring you a damage inspection App powered by Moblyx and M Inspect.';
    document.head.querySelector('meta[name="keywords"]').content = 'Vehicle Inspection App, Compliance Inspection Solutions, Asset Inspection App, Damage Recovery App, Rental Inspection App, Damage Inspection App';
  }, []);

  return (
    <>
      <Header />
      <main className="big-purco" style={{ position: 'relative' }}>
        <LandingTitleSection />
        <PurcoBrandingSection />
        <MInspectSection />
        <RegistrationSection />
      </main>
    </>
  );
}
