import React from 'react';
import backgroundImage from '../../assets/backgrounds/background-1.jpg';


export default function LandingTitleSection() {
  return (
    <header className="landing-title">
      <div className="moblyxs" style={{ backgroundImage: `data:image/png;base64,${backgroundImage}` }} />
      <section className="intro">
        <div className="intro-container moblyx-centre">
          <h1 className="intro-title">Getting ready for the next-generation of mobility</h1>
          <p className="intro-nonsense">Moblyx designs Apps for all segments of the mobility market. We provide management solutions that can be used independently or together with other programs in your mobility technical landscape.</p>
          {/* <div className="intro-buttons">
            <a className="moblyx-button contact-us" href="#contact-us">Contact Us</a>
          </div> */}
        </div>
      </section>
    </header>
  );
}
