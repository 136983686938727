import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import Header from './components/structure/Header/Header';
import Main from './components/structure/Main';
import Footer from './components/structure/Footer';
import './App.css';
import LandingPage from './components/landing/LandingPage';
import PurCo from './components/purco/Purco';

function MInspectWrapper({ children }) {
  return (
    <>
      <Header />
      <Main>
        {children}
      </Main>
      <Footer />
    </>
  );
}

export default function App() {
  useEffect(() => {
    const BASE_URL = 'https://app.chatwoot.com';
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.src = `${BASE_URL}/packs/js/sdk.js`;
    s.parentNode.insertBefore(g, s);
    g.onload = () => {
      window.chatwootSDK.run({
        websiteToken: 'nTMeAR5csuJzjaZvy6tAmuvt',
        baseUrl: BASE_URL,
      });
    };
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/purco">
          <PurCo />
        </Route>
        <Route path="/">
          <MInspectWrapper>
            <LandingPage />
          </MInspectWrapper>
        </Route>
      </Switch>
    </Router>
  );
}
