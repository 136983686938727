import React, { useEffect } from 'react';
import LandingTitleSection from './LandingTitleSection';
import './Landing.css';
import LandingClaimsSection from './LandingClaimsSection';
import LandingMInspectSection from './LandingMInspectSection';
import LandingMCarpoolSection from './LandingMCarpoolSection';

function Divider({ style, className }) {
  return (
    <div className={className} style={{ borderBottom: '1px solid #f0f0f0', ...style }} />
  );
}



export default function LandingPage() {
  useEffect(() => {
    document.title = 'Moblyx - The Next Generation of Vehicle Management';
    document.head.querySelector('meta[name="description"]').content = 'Moblyx designs Apps for all segments of the mobility market. We provide management solutions that can be used independently or together with other programs in your mobility technical landscape.';
    document.head.querySelector('meta[name="keywords"]').content = 'Vehicle Sharing Solutions, Carpool App, Vehicle Mobility App, Vehicle Inspection App, Compliance Inspection Solutions, Asset Inspection App, Vehicle Movements App';
  }, []);

  return (
    <main style={{ position: 'relative' }}>
      <LandingTitleSection />
      {/* <LandingClaimsSection /> */}
      {/* <Divider /> */}
      <LandingMInspectSection />
      <Divider />
      <LandingMCarpoolSection />
    </main>
  );
}
